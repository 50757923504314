import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { Img } from "react-image";
import LogoSvg from "assets/iLabDx-Logo.png";
import { validateForm } from "utils/validation";
import extractNumber from "utils/extractNumber";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  create,
  cancelSave,
  setErrors,
  removeErrors,
  loadPage,
  notFound,
  registerPatient,
  getEmployerShortInfoById,
} from "actions/admin/testOrderAction";
import Spinner from "views/Spinner";
import GMaps from "utils/GMaps";
import "react-times/css/classic/default.css";
import InsuranceDetails from "./InsuranceDetails";
import getAge from "utils/getAge";
import {
  uniqueString,
  todayStandardDate,
  isAllegisOrg,
} from "utils/nodeHelper";
import validateUsername from "utils/validateUsername";

const AddEmployee = ({
  currentEmployer,
  cancelSave,
  history,
  errorList,
  setErrors,
  removeErrors,
  match,
  loadPage,
  orgShortInfo,
  location,
  getEmployerShortInfoById,
  registerPatient,
}) => {
  const organizationID = match.params.organizationID;
  const searchParams = queryString.parse(location.search);
  const locationId = searchParams.locationId;
  const patientName = searchParams.patientName;

  if (!organizationID) {
    history.push("/");
  }

  const [invalidAccess, setInvalidAccess] = React.useState(false);

  useEffect(() => {
    if (organizationID) {
      getEmployerShortInfoById(organizationID).then((res) => {
        if (res.status === 401) setInvalidAccess(true);
      });
    }
  }, [organizationID]);

  const [companyLocationData, setCompanyLocationData] = React.useState([]);
  const [formUpdated, setFormUpdated] = useState(false);
  const [popupClosingMessage, setPopupClosingMessage] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  React.useEffect(() => {
    if (!orgShortInfo.locations || !orgShortInfo.locations.length) return;

    const { locations } = orgShortInfo;

    setCompanyLocationData(locations);

    setFormData({
      ...formData,
      companyLocation: {
        label: locations[0].addressLabel,
        value: locations[0]._id,
      },
    });
  }, [orgShortInfo]);

  const [testingType, setTestingType] = useState([]);

  const initialState = {
    fullName: patientName ? patientName : "",
    email: "",
    paymentBy: "1",
    subscriberId: "",
    insuranceCompany: "",
    companyLocation: {
      value: "",
      label: "Select",
    },
    ccode: "+1",
    phone: "",
    iso: "US",
    iute164_phone: "",
    line_1: "",
    line_2: "",
    dob: "",
    isChild: false,
    isPregnant: false,
    parentsApproval: "no",
    minDate: todayStandardDate(),
    a_city: {
      formatted_address: "",
      city_name: "",
      state: "",
      country: "",
      stateAbbr: "",
      short: "",
      location: {
        south: "",
        west: "",
        north: "",
        east: "",
      },
      place_id: "",
    },
    a_city_placeholder: "",
    zip_code: "",
    county: "",
    city_id: "",
    gender: "",
    secondaryUsername: "",
    race: "",
    ethnicity: "",
    ssn: "",
    jobNumber: "",
    alternativePhoneCCode: "+1",
    alternativePhone: "",
    alternativePhoneISO: "US",
    alternativePhone_full: "",
    occupation: "",
    employedInHealthcare: "",
    p_insuranceId: "",
    p_insuranceName: "",
    p_state: "",
    p_group: "",
    p_effectiveDate: "",
    p_expirationDate: "",
    p_insurancePhone: "",
    p_plan: "",
    p_insurancePhone_iute164: "",
    p_insurancePhone_ccode: "+1",
    p_nameOfInsured: "",
    p_relationToPatient: "",
    p_dob: "",
    s_insuranceId: "",
    s_insuranceName: "",
    s_state: "",
    s_group: "",
    s_effectiveDate: "",
    s_expirationDate: "",
    s_insurancePhone: "",
    s_plan: "",
    s_insurancePhone_iute164: "",
    s_insurancePhone_ccode: "+1",
    s_nameOfInsured: "",
    s_relationToPatient: "",
    s_dob: "",

    // vaccine
    vaccineType: "",
    firstDoseDone: "no",
    firstDoseDate: "",
    secondDoseDone: "no",
    secondDoseDate: "",
    boosterDone: "no",
    boosterDate: "",
  };
  const [formData, setFormData] = useState(initialState);

  const {
    a_city_placeholder,
    line_1,
    line_2,
    companyLocation,
    dob,
    isChild,
    parentsApproval,
    zip_code,
    county,
    fullName,
    email,
    minDate,
    ccode,
    iute164_phone,
    gender,
    ssn,
    isPregnant,
  } = formData;

  const handleSelect = (name, data) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  //validating secondary username.
  let [timeout, setTimeOutVariable] = useState(null); //useState to prevent timeout get cleared every time app renders
  const onKeyUp = async (e) => {
    removeErrors();
    clearTimeout(timeout);
    const newUsername = e.target.value;
    const fieldName = e.target.name;
    // Make a new timeout set to go off in 1000ms (1 second)
    setTimeOutVariable(
      setTimeout(async () => {
        let res = await validateUsername(newUsername);
        const errors = [];
        if (res.status && res.msg) {
          errors.push({
            param: fieldName,
            msg: res.msg,
          });
          setErrors(errors);
        }
      }, 2000)
    );
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "isPregnant":
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        break;
      case "parentsApproval":
        if (e.target.checked) {
          setFormData({
            ...formData,
            [e.target.name]: "yes",
          });
        } else {
          setFormData({
            ...formData,
            [e.target.name]: "no",
          });
        }
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };
  const onPhoneChange = (number) => {
    const phone = extractNumber(number, ccode);

    setFormData((form) => ({
      ...form,
      phone: phone,
      iute164_phone: number || ccode,
    }));
  };
  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      iso: code || "US",
      ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  const onDateChange = (e, time) => {
    const age = getAge(e.target.value);
    setFormData({
      ...formData,
      dob: e.target.value,
      isChild: age < 18 ? true : false,
      parentsApproval: "no",
    });
  };

  //############### Set Values using use Effect ###############################
  useMemo(() => loadPage(), []);

  useEffect(() => {
    const asyncParams = async () => {
      try {
        if (currentEmployer) {
          const city_id = currentEmployer.address.city._id;
          setFormData((form) => ({ ...form, ...{ city_id } }));
          setTestingType(currentEmployer.testingType ?? []);
        }
      } catch (err) {}
    };
    asyncParams();
  }, [currentEmployer]);

  const wrapInsuranceFields = (submitData) => {
    submitData.primaryInsurance = {};
    submitData.secondaryInsurance = {};

    const insuranceCommonFieilds = [
      "insuranceCardFront",
      "insuranceCardBack",
      "insuranceId",
      "insuranceName",
      "state",
      "group",
      "effectiveDate",
      "expirationDate",
      "insurancePhone",
      "insurancePhone_iute164",
      "insurancePhone_ccode",
      "plan",
      "nameOfInsured",
      "relationToPatient",
      "dob",
    ];
    insuranceCommonFieilds.forEach((field) => {
      if (submitData["p_" + field]) {
        submitData.primaryInsurance[field] = submitData["p_" + field];
        delete submitData["p_" + field];
      }
      if (submitData["s_" + field]) {
        submitData.secondaryInsurance[field] = submitData["s_" + field];
        delete submitData["s_" + field];
      }
    });
    return submitData;
  };
  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();

    removeErrors();
    let validationRules = [
      {
        param: "fullName",
        msg: "Please provide the name",
      },
      {
        param: "dob",
        msg: "Please provide a valid date of birth",
      },
      {
        param: "ssn",
        msg: "Please provide the SSN",
      },
      {
        param: "gender",
        msg: "Please provide a valid gender",
      },
    ];

    let error = validateForm(formData, validationRules);

    if (!error) error = [];

    if (formData.fullName) {
      const nameArray = formData.fullName.split(" ");
      if (nameArray.length < 2 || !nameArray[nameArray.length - 1].trim()) {
        error.push({
          param: "fullName",
          msg: "The last name is required.",
        });
      }
    }

    if (formData.dob && formData.isChild && formData.parentsApproval == "no") {
      error.push({
        param: "parentsApproval",
        msg: "Please ensure you have parents approval.",
      });
    }

    if (error.length) {
      setErrors(error);
      return;
    }

    let submitData = {
      employerId: orgShortInfo._id,
    };
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    submitData.race = submitData.race ? submitData.race.value : "";

    submitData = wrapInsuranceFields(submitData);

    if (
      submitData.primaryInsurance &&
      submitData.primaryInsurance.insuranceId
    ) {
      if (!submitData.primaryInsurance.insuranceName)
        error.push({
          param: "p_insuranceName",
          msg: "Please select insurance Name",
        });
    }
    if (
      submitData.secondaryInsurance &&
      submitData.secondaryInsurance.insuranceId
    ) {
      if (!submitData.secondaryInsurance.insuranceName)
        error.push({
          param: "s_insuranceName",
          msg: "Please select insurance Name",
        });
    }
    if (error.length) {
      setErrors(error);
      return;
    }

    setLoadingSubmit(true);
    registerPatient(submitData).then((res) => {
      setLoadingSubmit(false);
      if (res && res.status) {
        setFormUpdated(true);
        if (window.opener) {
          const patientId =
            res.response && res.response ? res.response._id : null;
          window.opener.onSuccess({ patientId, employerID: organizationID });
          setPopupClosingMessage(true);
          setTimeout(() => {
            window.close();
          }, 3000);
        }
      }
    });
  };

  return loadingSubmit ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <Helmet>
        <title>
          Patient Registration Form - {process.env.REACT_APP_APP_NAME}
        </title>
      </Helmet>

      <div className="add-patient-box">
        {formUpdated || invalidAccess ? (
          <div className="text-center">
            <Alert />
          </div>
        ) : (
          <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
            <Row>
              <Col md="12" className="mb-2">
                <Row>
                  <Col md="2" xs="12">
                    <div className="text-center w-100 d-block mb-1">
                      <Img alt="logo" src={LogoSvg} className="w-150" />
                    </div>
                  </Col>

                  <Col md="10" xs="12">
                    <div className="mt-5 offset-md-2">
                      <h3>Patient Registration Form</h3>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg="6" md="6" sm="12">
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Personal Information</h4>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label className="form-label" htmlFor="fullName">
                        Name *
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        maxLength="25"
                        value={fullName}
                        required
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.fullName ? true : false}
                      />
                      <Errors current_key="fullName" key="fullName" />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" htmlFor="dob">
                        Date of Birth *
                      </Label>
                      <Input
                        type="Date"
                        id="example-datepicker"
                        value={dob}
                        max={minDate}
                        autoComplete={uniqueString()}
                        onChange={(e) => onDateChange(e)}
                      />
                      <Errors current_key="dob" key="dob" />
                    </FormGroup>

                    {isChild && (
                      <FormGroup>
                        <Label>
                          <Input
                            type="checkbox"
                            name="parentsApproval"
                            value="no"
                            onChange={(e) => onChange(e)}
                            checked={parentsApproval == "yes"}
                            invalid={errorList.parentsApproval ? true : false}
                            style={{
                              position: "initial",
                              margin: "0 10px 0 0",
                            }}
                          />
                          <span className="text-dark">
                            Have parents approval? *
                          </span>
                        </Label>

                        <Errors
                          current_key="parentsApproval"
                          key="parentsApproval"
                        />
                      </FormGroup>
                    )}

                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <Label className="form-label-radio" htmlFor="gender">
                            Gender
                          </Label>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="radio"
                                name="gender"
                                value="m"
                                onChange={(e) => onChange(e)}
                                checked={!!gender.match(/m/i)}
                              />
                              Male
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="radio"
                                name="gender"
                                value="f"
                                onChange={(e) => onChange(e)}
                                checked={!!gender.match(/f/i)}
                              />
                              Female
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="radio"
                                name="gender"
                                value="o"
                                onChange={(e) => onChange(e)}
                                checked={!!gender.match(/o/i)}
                              />
                              Other
                            </Label>
                          </FormGroup>
                          <Errors current_key="gender" key="gender" />
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <Label htmlFor="ssn"> SSN # *</Label>
                          <Input
                            type="text"
                            id="ssn"
                            name="ssn"
                            maxLength="100"
                            value={ssn}
                            required
                            autoComplete={uniqueString()}
                            onChange={(e) => onChange(e)}
                            invalid={errorList.ssn ? true : false}
                          />
                          <Errors current_key="ssn" key="ssn" />
                        </FormGroup>
                      </Col>
                    </Row>

                    {gender.match(/f/i) && testingType.includes("covid-19") ? (
                      <FormGroup check inline>
                        <Label check style={{ marginBottom: "20px" }}>
                          <Input
                            type="checkbox"
                            name="isPregnant"
                            id="isPregnant"
                            onChange={(e) => onChange(e)}
                            checked={isPregnant}
                            invalid={errorList.isPregnant ? true : false}
                          />
                          Is the patient pregnant?
                        </Label>
                      </FormGroup>
                    ) : null}

                    <FormGroup>
                      <Label className="form-label" htmlFor="email">
                        Email
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        maxLength="50"
                        value={email}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.email ? true : false}
                      />
                      <Errors current_key="email" key="email" />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label" htmlFor="phone">
                        Phone
                      </Label>
                      <PhoneInput
                        className="hk_custom_sag"
                        autoComplete={uniqueString()}
                        international={true}
                        defaultCountry="US"
                        countryOptionsOrder={["US", "|", "..."]}
                        value={iute164_phone}
                        name="phone"
                        addInternationalOption={false}
                        onChange={onPhoneChange}
                        onCountryChange={onCountryChange}
                      />
                      <Errors current_key="phone" key="phone" />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="6" sm="12">
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Address Information</h4>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label htmlFor="line_1"> Address Line 1</Label>
                      <Input
                        type="text"
                        id="line_1"
                        name="line_1"
                        maxLength="100"
                        value={line_1}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.line_1 ? true : false}
                      />
                      <Errors current_key="line_1" key="line_1" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="line_2"> Address Line 2</Label>
                      <Input
                        type="text"
                        id="line_2"
                        name="line_2"
                        maxLength="100"
                        value={line_2}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.line_2 ? true : false}
                      />
                      <Errors current_key="line_2" key="line_2" />
                    </FormGroup>
                    <FormGroup>
                      <GMaps
                        label="City, State, Country"
                        setFormData={setFormData}
                        removeErrors={removeErrors}
                        setErrors={setErrors}
                        placeholder={
                          a_city_placeholder
                            ? a_city_placeholder
                            : "Please Enter Your City Name"
                        }
                        types={"(cities)"}
                        autofill="false"
                        autoComplete={uniqueString()}
                        isRequired={false}
                        invalid={errorList.a_city ? true : false}
                      />
                      <Errors current_key="a_city" key="a_city" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="address"> Zip Code </Label>
                      <Input
                        type="text"
                        id="zip_code"
                        name="zip_code"
                        maxLength="15"
                        value={zip_code}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.zip_code ? true : false}
                      />
                      <Errors current_key="zip_code" key="zip_code" />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="county">County</Label>
                      <Input
                        type="text"
                        id="county"
                        name="county"
                        value={county}
                        required={false}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.county ? true : false}
                      />
                      <Errors current_key="county" key="county" />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="companyLocation">
                        {isAllegisOrg(currentEmployer)
                          ? "Division"
                          : "Location"}
                      </Label>
                      <Select
                        cacheOptions
                        id="companyLocation"
                        name="companyLocation"
                        options={companyLocationData.map((loc) => ({
                          label: loc.addressLabel,
                          value: loc._id,
                        }))}
                        value={companyLocation}
                        onChange={(e) => handleSelect("companyLocation", e)}
                        invalid={errorList.companyLocation ? true : false}
                      />
                      <Errors
                        current_key="companyLocation"
                        key="companyLocation"
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="12" md="12" sm="12">
                <InsuranceDetails
                  formData={formData}
                  setFormData={setFormData}
                />
              </Col>

              <Col lg="12" md="12" sm="12">
                <Card>
                  <CardFooter>
                    <Button type="submit" color="primary" className="mr-1">
                      Save
                    </Button>
                    <Button type="reset" color="danger" onClick={onClickHandel}>
                      Cancel
                    </Button>
                  </CardFooter>
                </Card>
              </Col>

              {popupClosingMessage ? (
                <p
                  style={{
                    color: "green",
                    marginTop: "50px",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                >
                  <span>
                    Closing popup automatically in 3 second... If not closed in
                    3 seconds, please close it manually.
                  </span>
                </p>
              ) : null}
            </Row>
          </Form>
        )}
      </div>
    </React.Fragment>
  );
};

AddEmployee.propTypes = {
  create: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  orgShortInfo: state.testOrder.orgShortInfo,
});

export default connect(mapStateToProps, {
  notFound,
  create,
  cancelSave,
  removeErrors,
  setErrors,
  loadPage,
  getEmployerShortInfoById,
  registerPatient,
})(AddEmployee);
